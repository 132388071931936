import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import GradientIcon from '../AR-Dashboard/grad-icon';
import './datatable.css';
import api from "../../API";

function TagDatatable() {
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [newTagName, setNewTagName] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('data/tags');
                const fetchedRows = response.data.results;
                // Assign incremental IDs starting from 1
                const rowsWithIds = fetchedRows.map((row, index) => ({
                    ...row,
                    tag_index: index + 1,
                    index: index + 1 // Add index property
                }));
                setRows(rowsWithIds);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setNewTagName('');
        setOpen(false);
    };

    const handleSave = async () => {
        try {
            const newRow = { tag_name: newTagName };
            const response = await api.post('data/tags', newRow);
            // Assign a new incremental ID
            const newId = rows.length ? rows[rows.length - 1].tag_index + 1 : 1;
            const newRowWithId = {...response.data, tag_index: newId, index: newId};
            setRows([...rows, newRowWithId]);
            setNewTagName('');
            handleClose();
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && newTagName.trim()) {
            handleSave();
        }
    };

    const handleDelete = async (tag_id) => {
        try {
            await api.delete(`/data/tags?tag_id=${tag_id}`);
            const updatedRows = rows.filter((row) => row.tag_id !== tag_id).map((row, index) => ({
                ...row,
                tag_index: index + 1,
                index: index + 1 // Update index property
            }));
            setRows(updatedRows);
        } catch (error) {
            console.error('Error deleting data:', error);
        }
    };

    const columns = [
        {field: 'index', headerName: 'Index', width: 100},
        { field: 'tag_name', headerName: 'Name', width: 450 },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color='secondary'
                    onClick={() => handleDelete(params.id)}
                    startIcon={<GradientIcon IconComponent={DeleteIcon}/>}
                >
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <div>
            <h1 style={{ marginTop: '30px', fontFamily: 'sans-serif', color: '#432F60' }}>
                Tags
            </h1>
            <div className='DialogueBox' style={{ marginTop: '30px', marginLeft: '88%' }}>
                <React.Fragment>
                    <Button variant="contained" onClick={handleClickOpen} startIcon={<AddCircleOutlineIcon />}
                            size='small' sx={{background: 'linear-gradient(45deg, #674f87 30%, #b74770 90%)',}}>
                        Create
                    </Button>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle>Create New Tag</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="tag_name"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={newTagName}
                                onChange={(e) => setNewTagName(e.target.value)}
                                onKeyPress={handleKeyPress}
                                sx={{
                                    '& .MuiInput-underline:hover:before': {
                                        borderBottomColor: '#9E3C5D',
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: '#9E3C5D',
                                    },
                                    '& .MuiFormLabel-root.Mui-focused': {
                                        color: '#9E3C5D',
                                    },
                                }}

                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' color='secondary' onClick={handleClose}
                                    sx={{color: '#9E3C5D', backgroundColor: '#f1f1f1', border: '1px solid #9E3C5D '}}>
                                Cancel
                            </Button>
                            <Button variant='outlined' color='secondary' onClick={handleSave}
                                    disabled={!newTagName.trim()}
                                    sx={{color: '#9E3C5D', backgroundColor: '#f1f1f1', border: '1px solid #9E3C5D '}}>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </div>
            <div className='Table' style={{ height: 631, width: '95%', marginTop: '20px' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row.tag_id}
                    sx={{padding: 2}}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 15]}
                    autoHeight
                />
            </div>
        </div>
    );
}

export default TagDatatable;
