import React, {useState} from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import GradientIcon from './grad-icon';
import {Box, Button, Tooltip, styled, tooltipClasses} from '@mui/material';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import {useAuth} from "../../contexts/AuthContext";
import DocumentThumbnail from "../../components/Document/documentThumbnail";


const organizeData = (images) => {
    const data = {};
    images.forEach(image => {
        if (!data[image.document_type]) {
            data[image.document_type] = {};
        }
    });
    return data;
};

const CustomTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#d8d8d8',
        color: '#674f87',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));


const Folder = ({name, children, onDoubleClick, folderLevel, handleFolderLevel}) => {
    const [open, setOpen] = useState(false);
    const handleDoubleClick = () => {
        setOpen(!open);
        onDoubleClick && onDoubleClick();
        handleFolderLevel(folderLevel + 1);
    };

    const truncateName = (name, length) => {
        return name.length > length ? `${name.substring(0, length)}...` : name;
    };

    return (
        <Box
            onDoubleClick={handleDoubleClick}
            sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '1px',
                borderRadius: '4px',
            }}
        >
            <CustomTooltip title={name}>
                <Box
                    sx={{
                        display: 'flex',
                        width: '200px',
                        alignItems: 'center',
                        borderRadius: '4px',
                        '&:hover': {backgroundColor: '#fcdce0',},
                    }}
                >
                    <FolderIcon sx={{color: '#ef798a', fontSize: '64px'}}/>
                    <Typography
                        variant="body1"
                        sx={{
                            // color: '#674f87', 
                            marginInline: '8px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            maxWidth: '120px',
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                            maxHeight: '4em',
                            overflowY: 'auto',
                        }}
                    >
                        {truncateName(name, 12)}
                    </Typography>
                </Box>
            </CustomTooltip>
            <Box sx={{display: open ? 'block' : 'none'}}>
                {children}
            </Box>
        </Box>
    );
};

const FolderView = ({
                         docs,
                         downloadPdf,
                         handleItem,
                         handleDeleteOpen,
                         deleteOpen,
                         handleDeleteClose,
                         deleteDocument,
                         handleFolderLevel,
                         folderLevel
                     }) => {
    const {role} = useAuth();
    const data = organizeData(docs);
    const [currentPath, setCurrentPath] = useState([]);
    const navigateTo = (path) => {
        setCurrentPath(path);
    };
    const renderContents = (data, path = []) => {
        let filteredData = data;
        if (folderLevel === 0 || path.length === 0) {
            return Object.keys(filteredData).map((id) => (
                <Folder
                    key={id}
                    name={`${id}`}
                    onDoubleClick={() => navigateTo([id])}
                    folderLevel={folderLevel}
                    handleFolderLevel={handleFolderLevel}
                />
            ));
        } else if (folderLevel === 1 || path.length === 1) {
            const id = path[0];
            return Object.keys(filteredData[id]).map((docType) => (
                <Folder
                    key={docType}
                    name={docType}
                    onDoubleClick={() => navigateTo([id, docType])}
                    folderLevel={folderLevel}
                    handleFolderLevel={handleFolderLevel}
                />
            ));
        } else if (folderLevel === 2 || path.length === 2) {
            const id = path[0];
            const docType = path[1];
            return Object.keys(filteredData[id][docType]).map((docName) => (
                <Folder
                    key={docName}
                    name={docName}
                    onDoubleClick={() => navigateTo([id, docType, docName])}
                    folderLevel={folderLevel}
                    handleFolderLevel={handleFolderLevel}
                />
            ));
        } else if (folderLevel === 3 || path.length === 3) {
            const id = path[0];
            const docType = path[1];
            const docName = path[2];
            return filteredData[id][docType][docName].map((file) => (
                <DocumentThumbnail
                    key={file.document_id}
                    role={role}
                    document={file}
                    handleItem={handleItem}
                    downloadPdf={downloadPdf}
                    handleDeleteOpen={handleDeleteOpen}
                    deleteOpen={deleteOpen}
                    handleDeleteClose={handleDeleteClose}
                    deleteDocument={deleteDocument}
                />
            ));
        }
    };

    function handleBreadCrumb(event) {
        event.preventDefault();
    }

    const truncateName = (name, length) => {
        return name.length > length ? `${name.substring(0, length)}...` : name;
    };

    return (
        <GridWrapper sx={{paddingTop: '10px'}}>
            <Box sx={{
                marginTop: 2,
                marginBottom: 1,
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                width: '80%',
                background: '#ffffff'
            }}
            >

                {currentPath.length > 0 && (
                    <Button variant="outlined" size="small" onClick={() => setCurrentPath(currentPath.slice(0, -1))}
                            color="secondary" startIcon={<GradientIcon IconComponent={ArrowLeftIcon}/>}>
                        Back
                    </Button>
                )}

                {currentPath.length > 0 && (
                    <Box onClick={handleBreadCrumb}>
                        <Breadcrumbs aria-label="breadcrumb" sx={{fontWeight: 'bold', color: '#ef798a',}}>
                            <Link
                                underline="hover"
                                color="inherit"
                                onClick={() => setCurrentPath([])}
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#674f87',
                                    '&:hover': {color: '#ef798a',},
                                    cursor: 'pointer',
                                    margin: '0 8px'
                                }}
                            >
                                Documents
                            </Link>
                            {currentPath.map((path, index) => (
                                index < currentPath.length - 1 ? (
                                    <CustomTooltip title={path}>
                                        <Link
                                            underline="hover"
                                            color="inherit"
                                            key={path}
                                            onClick={() => setCurrentPath(currentPath.slice(0, index + 1))}
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#674f87',
                                                '&:hover': {color: '#ef798a',},
                                                cursor: 'pointer',
                                                margin: '0 8px',
                                            }}
                                        >
                                            {truncateName(path, 12)}
                                        </Link>
                                    </CustomTooltip>
                                ) : (
                                    <CustomTooltip title={path}>
                                        <Typography
                                            color="text.primary"
                                            key={path}
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#674f87',
                                            }}
                                        >
                                            {truncateName(path, 12)}
                                        </Typography>
                                    </CustomTooltip>
                                )
                            ))}
                        </Breadcrumbs>
                    </Box>
                )}
            </Box>
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, overflowInline: 'hidden', marginTop: 2}}>
                {renderContents(data, currentPath)}
            </Box>
        </GridWrapper>
    );
};

export default FolderView;