import React, {useContext} from 'react';
import {CircularProgress, Typography, Box} from '@mui/material';
import ProgressContext from '../../contexts/ProgressContext';

const ProgressWidget = () => {
    const {progress, docCount, isUploading} = useContext(ProgressContext);
    return (
        isUploading && (
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    width: 120,
                    height: 140, // Adjusted height to provide better space
                    backgroundColor: '#f0f0f0',
                    borderRadius: '10px',
                    padding: 2,
                    zIndex: 1000,
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="body2"
                    align="center"
                    sx={{marginBottom: 2}} // Added more space below text
                >
                    Import: {docCount}
                </Typography>

                <Box
                    position="relative"
                    display="inline-flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress
                        variant="determinate"
                        value={progress}
                        size={60} // Slightly larger for better fit
                        thickness={5}
                    />
                    <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        sx={{transform: 'translate(-50%, -50%)'}}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            color="textSecondary"
                            sx={{fontWeight: 'bold'}} // Slightly bolder text for visibility
                        >
                            {`${Math.round(progress)}%`}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
    );
};

export default ProgressWidget;
