import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {AuthProvider} from './contexts/AuthContext';
import './index.css';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Help from './pages/Help/Help';
import {ThemeProvider} from '@mui/material/styles';
import {dashboardTheme} from './dashboardTheme';
import {NavbarProvider} from './components/Navbar/NavbarContext';
import {AssistProvider} from './components/ai-assist/AssistContext';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Dashboard from './pages/AR-Dashboard/Dashboard';
import TagDatatable from './pages/Tags/TagDatatable';
import Status from './pages/status/status';
import Logs from './pages/Log/logs';
import DropBox from './pages/AR-Dashboard/DropBox';

const Root = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(
        localStorage.getItem('isLoggedIn') === 'true'
    );
    const handleLogin = () => {
        setIsLoggedIn(true);
    };

    return (
        <BrowserRouter>
            <AuthProvider>
                <NavbarProvider>
                    <AssistProvider>
                        <ThemeProvider theme={dashboardTheme}>
                            <Routes>
                                <Route path="/login" element={<Login onLogin={handleLogin}/>}/>
                                <Route path="/" element={<App isLoggedIn={isLoggedIn} handleLogin={handleLogin}/>}>
                                    <Route path="" element={<Home/>}/>
                                    <Route path="documents" element={<Dashboard/>}/>
                                    <Route path="help" element={<Help/>}/>
                                    <Route path="tags" element={<TagDatatable/>}/>
                                    <Route path="drive" element={<Status/>}/>
                                    <Route path="logs" element={<Logs/>}/>
                                    <Route path="dropbox" element={<DropBox/>}/>
                                </Route>
                            </Routes>
                        </ThemeProvider>
                    </AssistProvider>
                </NavbarProvider>
            </AuthProvider>
        </BrowserRouter>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <Root/>
    </React.StrictMode>,
    document.getElementById('root')
);