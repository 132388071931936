/*
import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingButton = ({isLoading, onClick, circleSize, circleColor, startIcon, children, ...props}) => {
    return (
        <Button
            onClick={onClick}
            startIcon={isLoading ? <CircularProgress size={circleSize} sx={{color: circleColor}}/> : startIcon}
            {...props}
        >
            {children}
        </Button>
    );
};

export default LoadingButton;
*/
import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingButton = ({
                           isLoading,
                           onClick,
                           circleSize = 24,
                           circleColor = 'currentColor',
                           startIcon,
                           children,
                           ...props
                       }) => {
    return (
        <Button
            onClick={onClick}
            startIcon={
                isLoading ?
                    <CircularProgress size={circleSize} sx={{color: circleColor}}/>
                    :
                    startIcon
            }
            disabled={isLoading}
            aria-busy={isLoading}
            aria-live="polite"
            {...props}
        >
            {children}
        </Button>
    );
};

export default LoadingButton;
