import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Card, CardContent, Grid, Typography, Box} from '@mui/material';
import SourceIcon from '@mui/icons-material/Source';
import api from '../../API';

const GradientIcon = ({IconComponent}) => {
    return (
        <svg
            viewBox="0 0 24 24"
            style={{
                width: 50,
                height: 50,
            }}
        >
            <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    {/* <stop offset="1%" stopColor="#432F60" /> */}
                    {/* <stop offset="200%" stopColor="#9E3C5D" /> */}
                    <stop offset="1%" stopColor="#674f87"/>
                    <stop offset="200%" stopColor="#ef798a"/>
                </linearGradient>
            </defs>
            <IconComponent
                style={{
                    fill: 'url(#gradient)',
                }}
            />
        </svg>
    );
};


const OrderCard = () => {

    const [docTypes, setDocTypes] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTypes = async () => {
            try {
                const response = await api.get('data/doc_type');
                if (response.status !== 200) {
                    console.error('Files loading error', response.status);
                    return false;
                }
                const responseData = await response.data;
                const totalSum = responseData.results.length > 0 ? responseData.results.reduce((sum, item) => sum + item.total_count, 0) : 0;
                const updatedDocTypes = [{document_type: "Total", total_count: totalSum}, ...responseData.results];
                setDocTypes(updatedDocTypes);
                return true;
            } catch (error) {
                console.error('Error fetching documents:', error);
            }
            return false;
        };
        fetchTypes();
    }, []);

    const handleNavigate = (selectedType) => {
        if (selectedType.document_type !== 'Total') {
            navigate('/documents', {state: {defaultDocType: selectedType}});
        } else {
            navigate('/documents', {state: {}});
        }
    };


    return (
        <Box sx={{marginTop: '30px'}}>
            <Grid container spacing={3}>
                {docTypes.map((type) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={type.document_type}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                                width: '90%',
                                background: '#f1f1f1',
                                color: "#674f87",
                                borderRadius: 5,
                                boxShadow: 3,
                                transition: 'all 0.3s ease-in-out',
                                mb: 4,
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: 6,
                                },
                            }}
                            onClick={() => handleNavigate(type)}
                        >
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    {type.document_type}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <GradientIcon IconComponent={SourceIcon}/>
                                    <Typography>
                                        <span style={{fontSize: 15, fontWeight: 'bold'}}>Total:</span>
                                        <span style={{fontSize: 42, marginLeft: 7}}>{type.total_count}</span>
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default OrderCard;
