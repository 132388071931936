import {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import api from '../API';

const useTokenExpirationCheck = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const accessToken = localStorage.getItem('refreshToken');
        if (!accessToken) return;

        const handleTokenRefreshFailure = () => {
            localStorage.clear();
            navigate('/login');
        };

        const checkTokenValidity = async () => {
            const {exp} = jwtDecode(accessToken);
            const expirationTime = exp * 1000; // Convert to milliseconds
            const currentTime = Date.now();
            const timeLeft = expirationTime - currentTime;

            if (timeLeft < 60000) { // Check if less than 1 minute remaining
                try {
                    const response = await api.post('auth/get_access_token', {
                        realm: localStorage.getItem('realm'),
                        username: localStorage.getItem('username'),
                    });
                    if (response.status === 200) {
                        localStorage.setItem('accessToken', response.data.token.access_token);
                        localStorage.setItem('refreshToken', response.data.token.refresh_token);
                        console.log("success to refresh access token");
                    } else {
                        console.log("Failed to refresh access token");
                        handleTokenRefreshFailure();
                    }
                } catch (error) {
                    console.log('Error refreshing access token:');
                    handleTokenRefreshFailure();
                }
            }
        };

        const intervalId = setInterval(checkTokenValidity, 60000); // Check every 1 minute

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [navigate]);
};

export default useTokenExpirationCheck;
