import React, {useState, useEffect} from 'react';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import {Icon} from '@mui/material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

import GradientIcon from './grad-icon'

const HeadingTypePopover = ({types, header, header_icon, onChangeFunction, selectedTypes}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    /* const handleTypeSelect = (type_) => {
        if (selectedTypes.includes(type_)) {
            onChangeFunction({selectedTypes: selectedTypes.filter((t) => t.document_type !== type_.document_type)});
        } else {
            onChangeFunction({selectedTypes: [...selectedTypes, type_]});
        }
     };*/
    const handleTypeSelect = (type_) => {
        if (selectedTypes.some((t) => t.document_type === type_.document_type)) {
            onChangeFunction({
                selectedTypes: selectedTypes.filter((t) => t.document_type !== type_.document_type)
            });
        } else {
            onChangeFunction({
                selectedTypes: [...selectedTypes, type_]
            });
        }
    };

    const filteredTypes = types.filter((type_) =>
        type_.document_type.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <Button variant="outlined" size="small" onClick={handleClick} color="secondary"
                    startIcon={<GradientIcon IconComponent={header_icon}/>}>
                {header}
                <Icon><GradientIcon IconComponent={ArrowDropDownOutlinedIcon}/></Icon>
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <TextField
                    label="Search Types"
                    variant="outlined"
                    value={searchTerm}
                    color='secondary'
                    size='small'
                    onChange={handleSearchChange}
                    style={{margin: '16px'}}
                />

                <div style={{maxHeight: '200px', overflowY: 'auto'}}>
                    <List dense>
                        {filteredTypes.map((type_) => (
                            <ListItem key={type_.document_type} button onClick={() => handleTypeSelect(type_)}>
                                <Checkbox
                                    // checked={selectedTypes.includes(type_)}
                                    checked={selectedTypes.some((t) => t.document_type === type_.document_type)}
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                    color='secondary'
                                />
                                <ListItemText primary={type_.document_type} color='secondary'/>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Popover>
        </div>
    );
};

export default HeadingTypePopover;