import axios from 'axios';

const apiURL = process.env.REACT_APP_BACKEND_API_URL;
const api = axios.create({
    baseURL: apiURL,
    timeout: 50000,
    headers: {
        'Content-Type': 'application/json',
    }
});

api.interceptors.request.use(
    async (config) => {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const username = localStorage.getItem('username');
        const realm = localStorage.getItem('realm');

        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        if (refreshToken) {
            config.headers['x-refresh-token'] = refreshToken;
        }

        if (['GET', 'DELETE'].includes(config.method.toUpperCase())) {
            config.params = config.params || {};
            if (username) {
                config.params.username = username;
            }
            if (realm) {
                config.params.realm = realm;
            }
        } else {
            config.data = config.data || {};
            if (username) {
                config.data.username = username;
            }
            if (realm) {
                config.data.realm = realm;
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        const handleTokenRefreshFailure = (messageToPop = null) => {
            localStorage.clear();
            if (messageToPop) {
                localStorage.setItem('logout_message', messageToPop);
            }
            window.location.href = '/login';
        };

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const response = await api.post('auth/get_access_token', {
                    realm: localStorage.getItem('realm'),
                    username: localStorage.getItem('username'),
                });

                if (response.status === 200) {
                    localStorage.setItem('accessToken', response.data.token.access_token);
                    localStorage.setItem('refreshToken', response.data.token.refresh_token);
                    originalRequest.headers.Authorization = `Bearer ${response.data.token.access_token}`;
                    originalRequest.headers['x-refresh-token'] = response.data.token.refresh_token;
                    return api(originalRequest);
                }
            } catch (err) {
                console.error('Failed to refresh access token', err);
                handleTokenRefreshFailure();
            }
        } else if (error.response && error.response.status === 499) {
            handleTokenRefreshFailure('A new instance of this user has logged in.');
        }

        return Promise.reject(error);
    }
);

export default api;
