import api from "../API";

export const login = async (formData, setError) => {
    try {
        const response = await api.post('auth/login', formData);
        if (response.status !== 200) {
            setError('Check your credentials');
        }
        return {
            userAccessToken: response.data.token.token.access_token,
            userRefreshToken: response.data.token.token.refresh_token,
            realm: formData.realm,
            username: formData.username,
            userInfo: response.data.token.user_info,
            userRole: response.data.token.user_info.realm_access.roles
        };
    }
    catch (error) {
        console.error('Check your credentials');
    }
};


export const logout = async (setError) => {
    try {
        const formData = new FormData();
        formData.append('realm', localStorage.getItem('realm'));
        formData.append('username', localStorage.getItem('username'));
        const response = await api.post('auth/logout', formData);
        if (response.status !== 200) {
            console.error('Logout api failed');
            setError('Logout api failed')
            return false;
        }
        localStorage.clear();
        return true;
    } catch (error) {
        console.error('An error occurred during logout:', error);
        setError('An error occurred during logout');
    }
    return true;
};
