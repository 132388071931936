import React, {useState, useEffect, useRef} from 'react';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import './Image-grid.css';
import FormControl from '@mui/material/FormControl';
import {
    Select,
    MenuItem,
    TableRow,
    Stack,
    TableContainer,
    TableHead,
    TableBody,
    Table,
    Paper,
    TableFooter, TableCell, CircularProgress
} from '@mui/material';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import DisplayDoc from '../Doc-Display/doc-display';
import {useNavbar} from '../../components/Navbar/NavbarContext';
import FolderView from './FolderView';
import api from "../../API";
import {useAuth} from "../../contexts/AuthContext";
import Pagination from '../../components/Pagination/Pagination';
import CustomSnackbar from '../../components/SnackBar/Snackbar';
import eventEmitter from './EvenEmitter';
import DocumentThumbnail from "../../components/Document/documentThumbnail";
import FilterContent from "../../components/Filters/FilterContent"
import {useLocation} from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import SearchIcon from '@mui/icons-material/Search';
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import GradientIcon from "../../pages/AR-Dashboard/grad-icon";


export default function Dashboard() {
    const {role} = useAuth();
    const {setCollapsed} = useNavbar();
    const location = useLocation();
    const defaultDocType = (location.state && location.state.defaultDocType) ? location.state.defaultDocType : {};
    setCollapsed(false);
    const [params, setParams] = useState({docIds: [], tadIDs: [], docTypes: [], contentSearch: null, aiMessage: null});
    const [selectDoc, setSelectDoc] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [docs, setDocs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [previous, setPrevious] = useState(null);
    const [next, setNext] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [limit, setLimit] = useState(14);
    const [quietMode, setQuietMode] = useState(null);
    const [snackbarOpen, setSnackBarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [folderLevel, setFolderLevel] = useState(0);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [selectedDocumentIds, setSelectedDocumentsIds] = useState([]);
    const [isFolderType, setIsFolderType] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState([false, null]);
    const [openDrawerChat, setOpenDrawerChat] = React.useState(false);
    const chatContentRef = useRef(null);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = () => {
        if (message.trim() === '') return;
        const newMessages = [...messages, {from: 'user', text: message}];
        setMessages(newMessages);
        const query_params = params;
        query_params.inputMessage = message;
        query_params.FileIds = selectedDocumentIds;
        setParams(query_params);
        api.post('ai/ai_group_file_search', params)
            .then(response => {
                const newMessages = [
                    ...messages,
                    {from: 'user', text: message},
                    {from: 'backend', text: response.data.response}
                ];
                setMessages(newMessages);
                setMessage('');
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    React.useEffect(() => {
        if (selectedDocuments.length > 0) {
            setOpenDrawerChat(true);
        } else {
            setOpenDrawerChat(false);
        }
    }, [selectedDocuments]);

    const handleItemView = () => {
        setIsFolderType(false);
    };

    const handleFolderType = () => {
        setIsFolderType(true);
    };

    const handleCheckboxChange = (doc) => {
        if (Array.isArray(selectedDocuments)) {
            setSelectedDocuments(prevSelectedDocuments => {
                if (prevSelectedDocuments.some(temp => temp.document_id === doc.document_id)) {
                    // Remove document from selectedDocuments and selectedDocumentIds
                    setSelectedDocumentsIds(prevSelectedDocumentIds =>
                        prevSelectedDocumentIds.filter(id => id !== doc.document_id)
                    );
                    return prevSelectedDocuments.filter(temp => temp.document_id !== doc.document_id);
                } else {
                    // Add document to selectedDocuments and selectedDocumentIds
                    setSelectedDocumentsIds(prevSelectedDocumentIds =>
                        [...prevSelectedDocumentIds, doc.document_id]
                    );
                    return [...prevSelectedDocuments, doc];
                }
            });
        }
    };
    const fetchDocs = async (url, params) => {
        try {
            setIsLoading(true);
            const response = await api.get(url, {params});
            setIsLoading(false);
            if (response.status !== 200) {
                console.error('Files loading error', response.status);
                return false;
            }
            const responseData = response.data;
            setTotalPages(responseData.count);
            setPrevious(responseData.previous);
            setNext(responseData.next);
            setDocs(responseData.results);
            setThumbnail(responseData.results.map(doc => doc.document_thumbnail));
            return true;
        } catch (error) {
            console.error('Error fetching documents:', error);
            return false;
        }
    };


    const createParams = (aiMessage, docIds = [], tagsSearch = [], docTypeSearch = [], contentSearch = null) => {
        const query_params = {}
        query_params.docIds = docIds;
        query_params.docTypes = docTypeSearch;
        query_params.tadIDs = tagsSearch;
        query_params.contentSearch = contentSearch;
        query_params.aiMessage = aiMessage;
        if (docIds.length > 0) {
            query_params.document_ids = docIds.join(',');
        }
        if (tagsSearch.length > 0) {
            query_params.tags = tagsSearch.map(tag => tag.tag_id).join(',');
        }
        if (docTypeSearch.length > 0) {
            query_params.types = docTypeSearch.map(type => type.document_type).join(',');
        }
        if (contentSearch) {
            query_params.content_search = contentSearch;
        }
        query_params.latest = true;
        setParams(query_params);
    };

    useEffect(() => {
        const query_params = params;
        query_params.limit = limit;
        query_params.offset = (currentPage - 1) * limit;
        setParams(query_params);
        if (!Object.keys(defaultDocType).length > 0) {
            fetchDocs('data/documents', query_params).then(success => setQuietMode(!success));
        }
    }, [params, limit, currentPage]);


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItem = (doc) => {
        setSelectDoc(doc);
        setRedirect(true);
    };

    const handleDocBack = () => {
        setRedirect(false);
    };

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };


    const deleteDocument = async (doc) => {
        setDeleteLoading(true);
        try {
            const doc_id = doc.document_id;
            const response = await api.delete(`data/documents?document_id=${doc_id}`);
            if (response.status === 200) {
                await fetchDocs('data/documents', params);
                console.log('Document deleted successfully');
                handleDeleteClose()
                showSnackbar('Document Deleted successfully');
            } else {
                console.error('Failed to delete document');
            }
        } catch (err) {
            console.error(err);
        }
        setDeleteLoading(false);
    };


    const downloadPdf = async (doc) => {
        setDownloadLoading([true, doc.document_id]);
        try {
            const doc_id = doc.document_id;
            const response = await api.get(`data/documents?document_id=${doc_id}&file=true&download=true`, {
                responseType: 'arraybuffer'
            });
            const fileType = response.headers['content-type'] || 'application/octet-stream';
            const docBlob = new Blob([response.data], {type: fileType});
            const docDataUrl = URL.createObjectURL(docBlob);
            const link = document.createElement('a');
            link.href = docDataUrl;
            link.download = doc.document_super_name + doc.document_version_location.substring(doc.document_version_location.lastIndexOf('.'));
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(docDataUrl);
            document.body.removeChild(link);
            showSnackbar('Document Downloaded successfully');
        } catch (err) {
            console.error(err);
        }
        setDownloadLoading([false, null]);
    };

    const handleLimitChange = (event) => {
        setLimit(Number(event.target.value));
        setCurrentPage(1);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackBarOpen(true);
    };

    const handleFolderLevel = (currentLevel) => {
        setFolderLevel(currentLevel);
    };

    useEffect(() => {
        const handleRedirect = () => {
            setRedirect(false);
        };
        eventEmitter.on('redirectToHome', handleRedirect);
        return () => {
            eventEmitter.off('redirectToHome', handleRedirect);
        };
    }, []);

    if (redirect) {
        return (
            <GridWrapper>
                <DisplayDoc doc={selectDoc} doc_id={selectDoc.document_id} handleDocBack={handleDocBack}/>
            </GridWrapper>
        );
    } else {
        return (
            <>
                <TableContainer component={Paper} sx={{width: '85vw', height: '90vh'}}>
                    <Table stickyHeader sx={{height: '100%', width: '100%'}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FilterContent
                                        createParams={createParams}
                                        showSnackbar={showSnackbar}
                                        handleFolderType={handleFolderType}
                                        handleItemView={handleItemView}
                                        isFolderType={isFolderType}
                                        params={params}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {isLoading ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: {xs: '50vh', sm: '60vh'},
                                            }}
                                        >
                                            <CircularProgress size={48} color="secondary"/>
                                        </Box>
                                    ) : (
                                        isFolderType ? (
                                            <FolderView
                                                docs={docs}
                                                downloadPdf={downloadPdf}
                                                downloadLoading={downloadLoading}
                                                handleItem={handleItem}
                                                handleDeleteOpen={handleDeleteOpen}
                                                deleteOpen={deleteOpen}
                                                handleDeleteClose={handleDeleteClose}
                                                deleteDocument={deleteDocument}
                                                deleteLoading={deleteLoading}
                                                handleFolderLevel={handleFolderLevel}
                                                folderLevel={folderLevel}
                                                handleCheckboxChange={handleCheckboxChange}
                                                selectedDocuments={selectedDocuments}/>
                                        ) : (
                                            <>
                                                <Box display="flex" flexDirection="column" gap={1}>
                                                    <Stack direction="row" spacing={1} alignItems="center"
                                                           marginLeft={2}>
                                                        <Typography variant="h5" gutterBottom>Documents:</Typography>
                                                        <Typography variant="h6" gutterBottom> {totalPages}</Typography>
                                                    </Stack>
                                                </Box>
                                                <Box className="image-grid"
                                                     sx={{maxHeight: '90%', overflow: 'auto', marginLeft: 1}}>
                                                    {(params.docIds.length > 0 ?
                                                            params.docIds
                                                                .map(id => docs.find(doc => doc.document_id.toString() === id))
                                                                .filter(doc => doc !== undefined) :
                                                            docs
                                                    ).map(doc => (
                                                        <DocumentThumbnail
                                                            key={doc.document_id}
                                                            role={role}
                                                            document={doc}
                                                            handleItem={handleItem}
                                                            downloadPdf={downloadPdf}
                                                            downloadLoading={downloadLoading}
                                                            handleDeleteOpen={handleDeleteOpen}
                                                            deleteOpen={deleteOpen}
                                                            handleDeleteClose={handleDeleteClose}
                                                            deleteDocument={deleteDocument}
                                                            deleteLoading={deleteLoading}
                                                            handleCheckboxChange={handleCheckboxChange}
                                                            selectedDocuments={selectedDocuments}
                                                        />
                                                    ))}
                                                </Box>
                                                <CustomSnackbar open={snackbarOpen} message={snackbarMessage}
                                                                onClose={handleCloseSnackbar}/>
                                            </>

                                        )
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        <TableFooter sx={{
                            position: 'sticky',
                            bottom: 0,
                            backgroundColor: (theme) => theme.palette.background.paper,
                            zIndex: 1000,
                        }}>
                            <TableRow><TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: 2,
                                    mt: 2,
                                    ml: 2,
                                    flexShrink: 0,
                                    flexDirection: {xs: 'column', sm: 'row'}, // Stack vertically on small screens
                                    gap: 1,
                                }}>
                                    <Typography sx={{marginRight: 1, fontSize: '0.875rem', fontWeight: 'bold'}}>Per
                                        Page:</Typography>
                                    <FormControl variant="outlined" size="small"
                                                 sx={{
                                                     minWidth: 80,
                                                     marginRight: 2,
                                                     '& .MuiOutlinedInput-root': {
                                                         '& fieldset': {
                                                             borderColor: '#674f87',
                                                         },
                                                         '&:hover fieldset': {
                                                             borderColor: '#ef798a',
                                                         },
                                                         '&.Mui-focused fieldset': {
                                                             borderColor: '#ef798a',
                                                         },
                                                     },
                                                 }}
                                    >
                                        <Select
                                            labelId="limit-label"
                                            id="limit"
                                            value={limit}
                                            onChange={handleLimitChange}
                                            sx={{fontSize: '1rem'}}
                                        >
                                            <MenuItem value={14}>14</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Pagination currentPage={currentPage} totalPages={totalPages}
                                                onPageChange={handlePageChange}
                                                limit={limit}/>
                                </Box>
                            </TableCell></TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

                <Drawer
                    sx={{
                        width: 400,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: 400,
                        },
                    }}
                    variant="persistent"
                    anchor="right"
                    open={openDrawerChat}
                >
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: '2rem',
                        lineHeight: 1.2,
                        marginTop: '70px',
                        textAlign: 'center',
                        letterSpacing: '0.01em',
                        background: 'linear-gradient(to right, #ef798a, #674f87)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                    }}>
                        <GradientIcon IconComponent={AutoAwesomeIcon}/>AI Assistant
                    </Typography>
                    <div className='chat-window'
                         style={{
                             border: "2px solid #fff",
                             height: "88%",
                             width: "99%",
                             margin: "auto",
                             borderRadius: "10px",
                             boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                             display: 'flex',
                             flexDirection: 'column',
                             justifyContent: 'space-between',
                         }}>
                        <div className='chat-content' ref={chatContentRef}
                             style={{flex: 1, padding: '10px', overflowY: 'auto'}}>
                            {messages.map((msg, index) => (
                                <div
                                    key={index}
                                    style={{
                                        textAlign: msg.from === 'user' ? 'right' : 'left',
                                        margin: '10px 0'
                                    }}
                                >
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            padding: '10px',
                                            borderRadius: '10px',
                                            backgroundColor: msg.from === 'user' ? '#525659' : '#d8d8d8',
                                            color: msg.from === 'user' ? '#fff' : '#000'
                                        }}
                                    >
                                        {msg.text}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className='chat-input'
                             style={{display: 'flex', borderTop: '1px solid #ccc', padding: '4px'}}>
                            <input
                                type='text'
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyPress={handleKeyPress}
                                style={{
                                    flex: 1,
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc'
                                }}
                                placeholder='Chat with the document now...'
                            />
                            <button
                                onClick={handleSearch}
                                style={{
                                    marginLeft: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #674F87',
                                    color: '#9D3C5D',
                                    cursor: 'pointer',
                                    alignItems: "center",
                                    backgroundColor: 'transparent'
                                }}
                            >
                                <SearchIcon
                                    style={{width: "22px", height: "22px", color: '#674F87'}}/>
                            </button>
                        </div>
                    </div>

                </Drawer>
            </>
        )
    }
};