import React, {useState, useEffect} from 'react';
import {
    Typography,
    TextField,
    Button,
    IconButton,
    InputAdornment,
    Box,
    FormControl
} from '@mui/material';
import {useAuth} from '../../contexts/AuthContext';
import {useNavigate} from 'react-router-dom';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import LogoText from '../images/metadocs_text.svg';
import LoadingButton from '../../components/Buttons/LoadingButton';

function LoginForm({onLogin, switchToSignup, showSnackbar}) {
    const {login, error} = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({username: '', password: '', realm: process.env.REACT_APP_REALM});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const logoutMessage = localStorage.getItem('logout_message');
        if (logoutMessage) {
            showSnackbar(logoutMessage);
            localStorage.removeItem('logout_message');
        }
        const refresh = localStorage.getItem('refreshToken');
        const realm = localStorage.getItem('realm');
        if (refresh && realm && localStorage.getItem('isLoggedIn')) {
            navigate('/');
        }
    }, [navigate, showSnackbar]);

    const handleLogin = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            const loginState = await login(formData);
            setLoading(false);
            if (loginState && !error) {
                onLogin();
                navigate('/');
            } else {
                setErrors({global: error});
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.username) errors.username = 'Username is required';
        if (!data.password) errors.password = 'Password is required';
        if (!data.realm) errors.realm = 'Realm is required';
        return errors;
    };

    return (
        <Box className="right-half">
            <Box className="content" sx={{display: 'block', width: 'auto', height: 'auto'}}>
                <Typography variant="h3" gutterBottom sx={{marginInline: 12}}>
                    Welcome To
                </Typography>
                <img src={LogoText} alt="login" className="loginText" style={{marginBottom: '16px', width: 450}}/>
                <FormControl id="loginForm">
                    <Box sx={{display: 'block', width: 450}}>
                        <TextField
                            fullWidth
                            label="Username"
                            name="username"
                            variant="outlined"
                            margin="normal"
                            value={formData.username}
                            onChange={handleChange}
                            error={!!errors.username}
                            helperText={errors.username}
                            sx={{
                                marginBottom: 3,
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: '#432F60',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#432F60',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    '&:hover': {
                                        color: '#432F60',
                                    },
                                    '&.Mui-focused': {
                                        color: '#432F60',
                                    },
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Password"
                            name="password"
                            variant="outlined"
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            value={formData.password}
                            onChange={handleChange}
                            error={!!errors.password}
                            helperText={errors.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: '#432F60',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#432F60',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    '&:hover': {
                                        color: '#432F60',
                                    },
                                    '&.Mui-focused': {
                                        color: '#432F60',
                                    },
                                },
                            }}
                        />
                    </Box>
                    <LoadingButton
                        isLoading={loading}
                        onClick={handleLogin}
                        circleSize={20}
                        circleColor={'#fff'}
                        disabled={loading}
                        sx={{
                            background: 'linear-gradient(315deg, #432F60 0%, #9E3C5D 60%)',
                            mt: 5,
                            borderRadius: 5,
                            color: '#fff'
                        }}
                    >
                        {loading ? (<span style={{color: '#fff'}}>Logging In...</span>) : 'SIGN IN'}
                    </LoadingButton>
                    {errors.global && <Typography color="error">{errors.global}</Typography>}
                </FormControl>
                <Typography sx={{position: 'fixed', bottom: '5%', right: '19%'}}>
                    Don't have an account? <span><Button onClick={switchToSignup} sx={{
                    color: '#9E3C5D',
                    fontWeight: 'bold'
                }}>Sign Up</Button></span>
                </Typography>
            </Box>
        </Box>
    );
}

export default LoginForm;
