import HomeIcon from '@mui/icons-material/Home';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DescriptionIcon from '@mui/icons-material/Description';

export const mainNavbarItems = [
    {
        id: 1,
        icon: <HomeIcon />,
        label: 'Home',
        route: '',
    },
    {
        id: 2,
        icon: <DescriptionIcon/>,
        label: 'Documents',
        route: 'documents',
    },
    {
        id: 3,
        icon: <LocalOfferIcon />,
        label: 'Tags',
        route: 'tags',
    },
    {
        id: 4,
        icon: <AddToDriveIcon />,
        label: 'Import',
        route: 'drive',
    },
    {
        id: 5,
        icon: <ListAltIcon/>,
        label: 'Activity Logs',
        route: 'logs',
    },

]

export const mainProfileItems = [
 /*   {
        id: 13,
        icon: <HelpIcon />,
        label: 'Help',
        route: 'help',
    },
    {
        id: 14,
        icon: <AccountBoxIcon />,
        label: 'Account Info',
        route: 'account-info',
    },*/
]