import React, {useState, useEffect} from 'react';
import {
    Box,
    Tabs,
    Tab,
    Typography,
    TableFooter,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper, Divider, Select, MenuItem
} from '@mui/material';
import axios from 'axios';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import ImportModal from '../../components/Modal/ImportModal';
import OpenModalButton from '../../components/Buttons/OpenModalButton';
import api from "../../API";
import Pagination from "../../components/Pagination/Pagination";
import FormControl from "@mui/material/FormControl";
import TuneIcon from '@mui/icons-material/Tune';
import CustomDatePicker from '../status/DatePicker';
import dayjs from 'dayjs';


const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#ef798a', // Change this to your tab color
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#ef798a', // Change this to your tab indicator color
                },
            },
        },
    },
});

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const Logs = () => {
    const [value, setValue] = useState(0);
    const [logDetails, setLogDetails] = useState([]);
    const [limit, setLimit] = useState(15);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState();

    const fetchLogDetails = async (url, params) => {
        try {
            const response = await api.get(url, {params});
            if (response.status !== 200) {
                console.error('Files loading error', response.status);
                return false;
            }
            const responseData = response.data;
            setLogDetails(responseData.results);
            setTotalPages(responseData.count);
            return true;
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
        return false;
    };
    const createParams = () => {
        const params = {};
        params.limit = limit;
        params.offset = (currentPage - 1) * limit;
        // Add date range to params if selected
        if (fromDate) {
            params.start_date = dayjs(fromDate).format('YYYY-MM-DD');
        }
        if (toDate) {
            params.end_date = dayjs(toDate).format('YYYY-MM-DD');
        }
        return params;
    };

    useEffect(() => {
        const params = createParams();
        fetchLogDetails('data/log_data', params);
    }, [limit, currentPage, fromDate, toDate]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleLimitChange = (event) => {
        setLimit(Number(event.target.value));
        setCurrentPage(1);
    };


    return (
        <div>
            <h2 style={{color: '#674f87', fontFamily: 'sans-serif'}}>
                Log Data
            </h2>
            <Box display="flex">
                <TuneIcon sx={{marginTop: 1, marginLeft: 3}}/>

                <CustomDatePicker
                    label="From Date"
                    value={fromDate}
                    onChange={(newValue) => {
                        setFromDate(newValue);
                    }}
                    maxDate={dayjs()} // Restricting the "From Date" to today or earlier
                />
                {/* To Date Picker */}
                <CustomDatePicker
                    label="To Date"
                    value={toDate}
                    onChange={setToDate}
                    minDate={fromDate || null}
                />
            </Box>
            <TabPanel value={value} index={0}>
                <LogDetails
                    logDetails={logDetails}
                    limit={limit}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    handleLimitChange={handleLimitChange}
                />
            </TabPanel>
        </div>
    );
};

const LogDetails = ({logDetails, limit, currentPage, totalPages, handlePageChange, handleLimitChange}) => {
    const [hoveredRow, setHoveredRow] = useState(null);

    return (
        <div>
            <TableContainer component={Paper} sx={{height: '70vh'}}>
                <Table stickyHeader sx={{minWidth: 650, height: '70vh'}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: 'bold', width: '12%'}}>Index</TableCell>
                            {/* <TableCell sx={{fontWeight: 'bold', width: '12%'}}>Origin</TableCell> */}
                            <TableCell sx={{fontWeight: 'bold', width: '12%'}}>User</TableCell>
                            <TableCell sx={{fontWeight: 'bold', width: '12%'}}>Activity</TableCell>
                            <TableCell sx={{fontWeight: 'bold', width: '15%'}}>Details</TableCell>
                            <TableCell sx={{fontWeight: 'bold', width: '15%'}}>Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logDetails.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {(currentPage - 1) * limit + index + 1}
                                </TableCell>
                                {/* <TableCell>{row.ip_address}</TableCell> */}
                                <TableCell
                                    style={{cursor: 'pointer', color: hoveredRow === index ? '#ef798a' : 'inherit'}}
                                >
                                    {row.username}
                                </TableCell>
                                <TableCell>{row.activity_type}</TableCell>
                                <TableCell>{row.activity_description}</TableCell>
                                <TableCell>{row.created_at}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter sx={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: (theme) => theme.palette.background.paper,
                        zIndex: 1000,
                    }}>
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 2, mt: 2}}>
                                    <Typography sx={{marginRight: 1, fontSize: '0.875rem', fontWeight: 'bold'}}>Per
                                        Page:</Typography>
                                    <FormControl variant="outlined" size="small"
                                                 sx={{
                                                     minWidth: 80,
                                                     marginRight: 2,
                                                     '& .MuiOutlinedInput-root': {
                                                         '& fieldset': {
                                                             borderColor: '#674f87',
                                                         },
                                                         '&:hover fieldset': {
                                                             borderColor: '#ef798a',
                                                         },
                                                         '&.Mui-focused fieldset': {
                                                             borderColor: '#ef798a',
                                                         },
                                                     },
                                                 }}
                                    >
                                <Select
                                    labelId="limit-label"
                                    id="limit"
                                    value={limit}
                                    onChange={handleLimitChange}
                                    sx={{fontSize: '1rem',}}
                                >
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                            </FormControl>
                            <Pagination currentPage={currentPage} totalPages={totalPages}
                                        onPageChange={handlePageChange} limit={limit}/>
                        </Box>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Logs;
