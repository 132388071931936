import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GradientIcon from "../../pages/AR-Dashboard/grad-icon";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import Delete from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import ButtonGroup from "@mui/material/ButtonGroup";
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from "../Buttons/LoadingButton";
import {useAuth} from "../../contexts/AuthContext";

const DocumentThumbnail = (documentArgs) => {
    const {isUser, isMaintainer, isAdmin, isSuperuser} = useAuth();
    const truncateName = (name, length) => {
        return name.length > length ? `${name.substring(0, length)}...` : name;
    };
    return (
        <Card key={documentArgs.document.document_id}
              sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  background: '#f1f1f1',
                  transition: 'box-shadow 0.3s ease-in-out',
                  ':hover': {boxShadow: 5,}
              }}>
            <Checkbox
                sx={{
                    color: '#A6A6A6',
                    '&.Mui-checked': {
                        color: 'black',
                    },
                    '& .MuiSvgIcon-root': {
                        fontSize: 20,
                    },
                }}
                style={{position: 'absolute', left: '-4px', top: '-4px'}}
                checked={documentArgs.selectedDocuments.some(temp => documentArgs.document.document_id === temp.document_id)}
                onChange={() => documentArgs.handleCheckboxChange(documentArgs.document)}
                color="primary"
                inputProps={{'aria-label': 'select document'}}
            />
            <CardMedia
                component="img"
                image={documentArgs.document.document_thumbnail ? documentArgs.document.document_thumbnail : ''}
                sx={{
                    width: '90%',
                    height: 150,
                    backgroundSize: 'cover',
                    cursor: 'pointer',
                    marginTop: 2,
                }}
                onClick={() => documentArgs.handleItem(documentArgs.document)}
            />
            <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 1
            }}>
                <Typography variant="subtitle2" title={documentArgs.document.document_sub_name}>
                    {truncateName(documentArgs.document.document_sub_name, 20)}
                </Typography>
            </CardContent>
            <CardActions>
                <ButtonGroup
                    aria-label="Basic button group" color="secondary"
                    size='small'
                    sx={{
                        background: '#fafafa',
                        marginBottom: '8px',
                    }}
                >
                    <Button
                        onClick={() => documentArgs.handleItem(documentArgs.document)}
                    >
                        <GradientIcon IconComponent={VisibilityIcon}/>
                    </Button>
                    <LoadingButton
                        isLoading={documentArgs.downloadLoading[0] && (documentArgs.downloadLoading[1] === documentArgs.document.document_id)}
                        onClick={() => documentArgs.downloadPdf(documentArgs.document)}
                        circleSize={20}
                        circleColor={'#220303'}
                        startIcon={<GradientIcon IconComponent={DownloadIcon}/>}
                        disabled={(documentArgs.downloadLoading[0] && (documentArgs.downloadLoading[1] === documentArgs.document.document_id)) || !(isAdmin || isMaintainer || isSuperuser)}
                    />
                    <LoadingButton
                        isLoading={documentArgs.deleteLoading}
                        onClick={documentArgs.handleDeleteOpen}
                        circleSize={20}
                        circleColor={'#220303'}
                        startIcon={<GradientIcon IconComponent={Delete}/>}
                        disabled={documentArgs.deleteLoading || !(isAdmin || isSuperuser)}
                        variant="outlined"
                        size="small"
                        color="secondary"
                    />
                </ButtonGroup>
                <React.Fragment>
                    <Dialog
                        open={documentArgs.deleteOpen}
                        onClose={documentArgs.handleDeleteClose}
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText
                                id="alert-dialog-description">
                                Are you sure you want to delete this file?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={documentArgs.handleDeleteClose}>No</Button>
                            <Button onClick={() => documentArgs.deleteDocument(documentArgs.document)}
                                    autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </CardActions>
        </Card>
    );
};

export default DocumentThumbnail;
