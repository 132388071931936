import React from 'react';
import { Button } from '@mui/material';

const OpenModalButton = ({ handleOpen }) => {
    return (
        <Button
            variant="contained"
            onClick={handleOpen}
            sx={{
                background: 'linear-gradient(45deg, #674f87 30%, #b74770 90%)',
                color: 'white',
                '&:hover': {
                    background: 'linear-gradient(45deg, #674f87 30%, #b74770 90%)',
                },
            }}
        >
            Import
        </Button>
    );
};

export default OpenModalButton;
